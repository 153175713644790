import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initFullStory } from './utils/fullstory';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Initialize FullStory in production only
if (process.env.NODE_ENV === 'production') {
  try {
    // Get orgId from environment variable
    const orgId = process.env.REACT_APP_FULLSTORY_ORG_ID;
    if (!orgId) {
      throw new Error('REACT_APP_FULLSTORY_ORG_ID is not defined');
    }
    initFullStory(orgId);
  } catch (error) {
    console.error('Failed to initialize FullStory:', error);
  }
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

import React, { useState, useEffect } from 'react';
import { Calendar, Clock, ChevronDown } from 'lucide-react';
import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8080' 
    : process.env.REACT_APP_API_URL || 'https://api.klickie.me';

interface TimeSlot {
    startTime: string;
    endTime: string;
}

interface TimeSlotSelectorProps {
    userId: string;
    serviceName: string;
    duration: string;
    onSelect: (slot: TimeSlot) => void;
    onCancel: () => void;
}

type WeekOption = 'this-week' | 'next-week' | 'in-two-weeks' | 'in-three-weeks';

interface AvailabilityMap {
    [date: string]: TimeSlot[];
}

export default function TimeSlotSelector({ userId, serviceName, duration, onSelect, onCancel }: TimeSlotSelectorProps) {
    const [selectedWeek, setSelectedWeek] = useState<WeekOption>('this-week');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [availabilityMap, setAvailabilityMap] = useState<AvailabilityMap>({});
    const [showAllSlots, setShowAllSlots] = useState<{[key: string]: boolean}>({});
    const [showMoreWeeks, setShowMoreWeeks] = useState(false);

    const weeks: WeekOption[] = ['this-week', 'next-week', 'in-two-weeks', 'in-three-weeks'];
    const weekLabels: {[key in WeekOption]: string} = {
        'this-week': 'THIS WEEK',
        'next-week': 'NEXT WEEK',
        'in-two-weeks': 'IN TWO WEEKS',
        'in-three-weeks': 'IN THREE WEEKS'
    };

    // Helper to get date range for a week
    const getWeekRange = (week: WeekOption) => {
        const today = new Date();
        const startDate = new Date(today);
        
        // Always start from today for this week
        if (week === 'this-week') {
            startDate.setHours(0, 0, 0, 0);
        } else {
            // For other weeks, start from the beginning of that week
            switch (week) {
                case 'next-week':
                    startDate.setDate(today.getDate() + 7);
                    break;
                case 'in-two-weeks':
                    startDate.setDate(today.getDate() + 14);
                    break;
                case 'in-three-weeks':
                    startDate.setDate(today.getDate() + 21);
                    break;
            }
            // Set to beginning of the week (Monday)
            const day = startDate.getDay();
            const diff = startDate.getDate() - day + (day === 0 ? -6 : 1);
            startDate.setDate(diff);
        }

        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        return { startDate, endDate };
    };

    // Fetch availability for the selected week
    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                setLoading(true);
                const { startDate, endDate } = getWeekRange(selectedWeek);
                
                const response = await axios.get(`${API_URL}/api/available-slots/${userId}`, {
                    params: {
                        startRange: startDate.toISOString(),
                        endRange: endDate.toISOString(),
                        duration: duration
                    }
                });

                // Filter out past slots and organize by date
                const now = new Date();
                now.setMinutes(now.getMinutes() + 30); // Add 30 min buffer
                
                // Sort slots chronologically first
                const sortedSlots = response.data.availableSlots
                    .filter((slot: TimeSlot) => new Date(slot.startTime) > now)
                    .sort((a: TimeSlot, b: TimeSlot) => 
                        new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
                    );

                // Group by date
                const newAvailabilityMap: AvailabilityMap = {};
                sortedSlots.forEach((slot: TimeSlot) => {
                    const date = new Date(slot.startTime).toLocaleDateString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    });
                    if (!newAvailabilityMap[date]) {
                        newAvailabilityMap[date] = [];
                    }
                    newAvailabilityMap[date].push(slot);
                });

                setAvailabilityMap(newAvailabilityMap);
            } catch (err) {
                console.error('Error fetching availability:', err);
                setError(err instanceof Error ? err.message : 'Failed to load availability');
            } finally {
                setLoading(false);
            }
        };

        fetchAvailability();
    }, [selectedWeek, userId, duration]);

    const formatTime = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString([], {
            weekday: 'long',
            month: 'long',
            day: 'numeric'
        });
    };

    const toggleShowAllSlots = (date: string) => {
        setShowAllSlots(prev => ({
            ...prev,
            [date]: !prev[date]
        }));
    };

    const visibleWeeks = showMoreWeeks ? weeks : weeks.slice(0, 2);

    return (
        <div className="fixed inset-0 bg-[#1E2738] overflow-y-auto pt-4 pb-20">
            <div className="w-full max-w-md mx-auto bg-[#1E2738] rounded-3xl p-8 relative">
                {/* Header */}
                <div className="text-center mb-8">
                    <h1 className="text-[#FFD700] text-3xl font-bold mb-2">{serviceName}</h1>
                    <div className="text-gray-400">
                        Select an available time slot
                    </div>
                </div>

                {/* Week Selection */}
                <div className="space-y-4 mb-8">
                    {visibleWeeks.map((week) => (
                        <button
                            key={week}
                            onClick={() => setSelectedWeek(week)}
                            className="w-full relative group"
                        >
                            <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                            <div className={`relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors ${selectedWeek === week ? '' : 'opacity-70'}`}>
                                <span>{weekLabels[week]}</span>
                            </div>
                        </button>
                    ))}
                    {!showMoreWeeks && (
                        <button
                            onClick={() => setShowMoreWeeks(true)}
                            className="w-full text-[#FFD700] py-2 flex items-center justify-center space-x-2 hover:text-[#FFD700]/80 transition-colors"
                        >
                            <span>MORE OPTIONS</span>
                            <ChevronDown className="w-4 h-4" />
                        </button>
                    )}
                </div>

                {/* Time Slots */}
                <div className="space-y-4">
                    {loading ? (
                        <div className="text-white text-center py-8">Loading available slots...</div>
                    ) : error ? (
                        <div className="text-red-500 text-center py-8">{error}</div>
                    ) : Object.keys(availabilityMap).length === 0 ? (
                        <div className="text-white/60 text-center py-8">
                            No available slots for this week
                        </div>
                    ) : (
                        Object.entries(availabilityMap).map(([date, slots]) => {
                            const showAll = showAllSlots[date] || false;
                            const visibleSlots = showAll ? slots : slots.slice(0, 5);
                            const hasMore = slots.length > 5;

                            return (
                                <div key={date} className="bg-[#1A202C] p-4 rounded-xl space-y-3">
                                    <h3 className="text-[#FFD700] font-semibold">{formatDate(date)}</h3>
                                    <div className="space-y-2">
                                        {visibleSlots.map((slot, index) => (
                                            <button
                                                key={index}
                                                onClick={() => onSelect(slot)}
                                                className="w-full relative group"
                                            >
                                                <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                                                <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                                                    <div className="flex items-center justify-center space-x-2">
                                                        <Clock className="w-5 h-5" />
                                                        <span>{formatTime(slot.startTime)}</span>
                                                    </div>
                                                </div>
                                            </button>
                                        ))}
                                        {hasMore && !showAll && (
                                            <button
                                                onClick={() => toggleShowAllSlots(date)}
                                                className="w-full text-[#FFD700] py-2 flex items-center justify-center space-x-2 hover:text-[#FFD700]/80 transition-colors"
                                            >
                                                <span>SHOW MORE SLOTS</span>
                                                <ChevronDown className="w-4 h-4" />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>

                {/* Back Button */}
                <button
                    onClick={onCancel}
                    className="w-full relative group mt-8"
                >
                    <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                    <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                        <span>BACK</span>
                    </div>
                </button>
            </div>
        </div>
    );
} 
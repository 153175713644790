import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export default function OnboardingProSchedule() {
  const { userId } = useParams() as { userId: string };
  const [availableDays, setAvailableDays] = useState<string[]>([]);
  const [defaultTimeSlot, setDefaultTimeSlot] = useState({ start: '09:00', end: '17:00' });
  const [defaultBreak, setDefaultBreak] = useState({ start: '12:00', end: '13:00' });
  const [exceptions, setExceptions] = useState<{ [key: string]: { timeSlots: { start: string; end: string }[], breaks: { start: string; end: string }[] } }>({});
  const [minNotice, setMinNotice] = useState('24');
  const [cancellationPolicy, setCancellationPolicy] = useState('12');
  const [maxBookingWindow, setMaxBookingWindow] = useState('3');

  const toggleDay = (day: string) => {
    setAvailableDays(prev => 
      prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]
    );
  };

  const updateDefaultTimeSlot = (field: 'start' | 'end', value: string) => {
    setDefaultTimeSlot(prev => ({ ...prev, [field]: value }));
  };

  const updateDefaultBreak = (field: 'start' | 'end', value: string) => {
    setDefaultBreak(prev => ({ ...prev, [field]: value }));
  };

  const addException = (day: string) => {
    setExceptions(prev => ({
      ...prev,
      [day]: { timeSlots: [{ ...defaultTimeSlot }], breaks: [{ ...defaultBreak }] }
    }));
  };

  const updateException = (day: string, type: 'timeSlots' | 'breaks', index: number, field: 'start' | 'end', value: string) => {
    setExceptions(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        [type]: prev[day][type].map((item, i) => 
          i === index ? { ...item, [field]: value } : item
        )
      }
    }));
  };

  const removeException = (day: string) => {
    setExceptions(prev => {
      const { [day]: _, ...rest } = prev;
      return rest;
    });
  };

  const validateForm = () => {
    if (availableDays.length === 0) {
      alert('Please select at least one day');
      return false;
    }

    if (!defaultTimeSlot.start || !defaultTimeSlot.end) {
      alert('Please set default time slot');
      return false;
    }

    if (!defaultBreak.start || !defaultBreak.end) {
      alert('Please set default break time');
      return false;
    }

    if (!minNotice || !cancellationPolicy || !maxBookingWindow) {
      alert('Please fill in all booking rules');
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      // Use localhost in development mode
      const API_URL = process.env.NODE_ENV === 'development' 
          ? 'http://localhost:8080'  // or whatever port your local server uses
          : process.env.REACT_APP_API_URL || 'https://api.klickie.me';
          
      console.log('Using API URL:', API_URL);

      const normalizedUserId = userId.startsWith('+') ? userId : `+${userId}`;

      // Save availability data
      const availabilityData = {
        userId: normalizedUserId,
        availableDays,
        defaultTimeSlot,
        defaultBreak,
        exceptions,
        bookingRules: {
          minNotice,
          cancellationPolicy,
          maxBookingWindow
        },
        sendPreview: true
      };
      console.log('Submitting availability data:', JSON.stringify(availabilityData, null, 2));

      const availabilityResponse = await axios.post(`${API_URL}/api/availability`, availabilityData);
      console.log('Availability response:', availabilityResponse.data);

      if (!availabilityResponse.data.success) {
        throw new Error(availabilityResponse.data.error || 'Failed to save availability');
      }

      // Redirect to WhatsApp
      window.location.href = 'https://api.whatsapp.com/send/?phone=3197010257400&type=phone_number&app_absent=0';
    } catch (error: any) {
      console.error('Error submitting form:', error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      alert(error.response?.data?.error || 'Error saving availability. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-[#1E2738] flex flex-col items-center p-4">
      <div className="w-full max-w-4xl space-y-6 py-12">
        <h1 className="text-[#FFD700] text-4xl mb-8 font-['Klickie_Font',_Arial,_sans-serif] text-center">
          Set Your Availability
        </h1>

        {/* Days Available Section */}
        <div className="bg-[#1A202C] p-6 rounded-xl">
          <h2 className="text-white text-2xl mb-4">Days Available</h2>
          <div className="flex flex-wrap gap-2">
            {daysOfWeek.map(day => (
              <button
                key={day}
                onClick={() => toggleDay(day)}
                className={`px-4 py-2 rounded-full ${
                  availableDays.includes(day)
                    ? 'bg-[#4895AA] text-white'
                    : 'bg-transparent text-white border border-white'
                }`}
              >
                {day}
              </button>
            ))}
          </div>
        </div>

        {/* Default Schedule Section */}
        <div className="bg-[#1A202C] p-6 rounded-xl">
          <h2 className="text-white text-2xl mb-4">Default Schedule</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-white text-xl mb-2">Default Time Slot</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <input
                    type="time"
                    value={defaultTimeSlot.start}
                    onChange={(e) => updateDefaultTimeSlot('start', e.target.value)}
                    className="w-full bg-white rounded-xl p-2"
                  />
                </div>
                <div>
                  <input
                    type="time"
                    value={defaultTimeSlot.end}
                    onChange={(e) => updateDefaultTimeSlot('end', e.target.value)}
                    className="w-full bg-white rounded-xl p-2"
                  />
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-white text-xl mb-2">Default Break</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <input
                    type="time"
                    value={defaultBreak.start}
                    onChange={(e) => updateDefaultBreak('start', e.target.value)}
                    className="w-full bg-white rounded-xl p-2"
                  />
                </div>
                <div>
                  <input
                    type="time"
                    value={defaultBreak.end}
                    onChange={(e) => updateDefaultBreak('end', e.target.value)}
                    className="w-full bg-white rounded-xl p-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Exceptions Section */}
        <div className="bg-[#1A202C] p-6 rounded-xl">
          <h2 className="text-white text-2xl mb-4">Exceptions</h2>
          {availableDays.map(day => (
            <div key={day} className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-white text-xl">{day}</h3>
                {exceptions[day] ? (
                  <button 
                    onClick={() => removeException(day)}
                    className="px-4 py-2 bg-[#4895AA] text-white rounded-lg"
                  >
                    Remove Exception
                  </button>
                ) : (
                  <button 
                    onClick={() => addException(day)}
                    className="px-4 py-2 bg-[#4895AA] text-white rounded-lg"
                  >
                    Add Exception
                  </button>
                )}
              </div>
              {exceptions[day] && (
                <div className="pl-4 space-y-4">
                  <div>
                    <h4 className="text-white text-lg mb-2">Time Slot</h4>
                    <div className="grid grid-cols-2 gap-4">
                      <input
                        type="time"
                        value={exceptions[day].timeSlots[0].start}
                        onChange={(e) => updateException(day, 'timeSlots', 0, 'start', e.target.value)}
                        className="w-full bg-white rounded-xl p-2"
                      />
                      <input
                        type="time"
                        value={exceptions[day].timeSlots[0].end}
                        onChange={(e) => updateException(day, 'timeSlots', 0, 'end', e.target.value)}
                        className="w-full bg-white rounded-xl p-2"
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-white text-lg mb-2">Break</h4>
                    <div className="grid grid-cols-2 gap-4">
                      <input
                        type="time"
                        value={exceptions[day].breaks[0].start}
                        onChange={(e) => updateException(day, 'breaks', 0, 'start', e.target.value)}
                        className="w-full bg-white rounded-xl p-2"
                      />
                      <input
                        type="time"
                        value={exceptions[day].breaks[0].end}
                        onChange={(e) => updateException(day, 'breaks', 0, 'end', e.target.value)}
                        className="w-full bg-white rounded-xl p-2"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Booking Rules Section */}
        <div className="bg-[#1A202C] p-6 rounded-xl">
          <h2 className="text-white text-2xl mb-4">Booking Rules</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="text-white mb-2 block">Minimum Notice (hours)</label>
              <input
                type="number"
                value={minNotice}
                onChange={(e) => setMinNotice(e.target.value)}
                className="w-full bg-white rounded-xl p-2"
              />
            </div>
            <div>
              <label className="text-white mb-2 block">Cancellation Policy (hours)</label>
              <input
                type="number"
                value={cancellationPolicy}
                onChange={(e) => setCancellationPolicy(e.target.value)}
                className="w-full bg-white rounded-xl p-2"
              />
            </div>
            <div>
              <label className="text-white mb-2 block">Maximum Booking Window (months)</label>
              <input
                type="number"
                value={maxBookingWindow}
                onChange={(e) => setMaxBookingWindow(e.target.value)}
                className="w-full bg-white rounded-xl p-2"
              />
            </div>
          </div>
        </div>

        <button
          onClick={handleSubmit}
          className="w-full h-[50px] bg-[#FFD700] hover:bg-[#FFD700]/90 text-black rounded-xl font-bold text-xl"
        >
          Save Availability Settings
        </button>
      </div>
    </div>
  );
}
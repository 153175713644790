import { useEffect, useState } from 'react'
import PhoneInput from '../components/PhoneInput'
import axios from 'axios'
import countries from "countries"

export default function Home() {
    const [country, setCountry] = useState('BE')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isValid, setIsValid] = useState(false)
    const [popupPhoneNumber, setPopupPhoneNumber] = useState('')
    const [isPopupValid, setIsPopupValid] = useState(false)

    useEffect(() => {
        const detectCountry = async () => {
            try {
                const response = await new Promise<{ country: string }>((resolve) => {
                    setTimeout(() => {
                        resolve({ country: 'BE' })
                    }, 1000)
                })

                setCountry(response.country)
            } catch (error) {
                console.error('Error detecting country:', error)
                setCountry('BE')
            }
        }

        detectCountry()
    }, [])

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>, isPopup: boolean = false) => {
        const newNumber = e.target.value
        if (isPopup) {
            setPopupPhoneNumber(newNumber)
            setIsPopupValid(newNumber.length > 0)
        } else {
            setPhoneNumber(newNumber)
            setIsValid(newNumber.length > 0)
        }
    }

    const handleSubmit = async (isPopup: boolean = false) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'https://api.klickie.me';
            console.log('Using API URL:', API_URL);
            
            const selectedPhoneNumber = isPopup ? popupPhoneNumber : phoneNumber;
            const selectedCountry = countries.find(c => c.code === country);

            if (!selectedCountry) {
                console.error('Invalid country selected:', country);
                alert('Invalid country selected.');
                return;
            }

            const cleanPhoneNumber = selectedPhoneNumber.replace(/\D/g, '');
            const fullPhoneNumber = `${selectedCountry.prefix}${cleanPhoneNumber}`;

            console.log('Submitting phone number:', {
                phoneNumber: cleanPhoneNumber,
                country,
                fullPhoneNumber
            });

            const response = await axios.post(`${API_URL}/new-phone-number`, {
                phoneNumber: cleanPhoneNumber,
                country,
                fullPhoneNumber
            });

            console.log('Server response:', response.data);

            if (response.data.success) {
                if (isPopup) {
                    setPopupPhoneNumber('');
                } else {
                    setPhoneNumber('');
                }
                alert('Great! Check your WhatsApp for next steps.');
            } else {
                throw new Error(response.data.message || 'Unknown error occurred');
            }
        } catch (error: any) {
            console.error('Error submitting phone number:', error);
            const errorMessage = error.response?.data?.message || error.message || 'Failed to submit phone number';
            alert(`Error: ${errorMessage}. Please try again.`);
        }
    };

    return (
        <div className="min-h-screen bg-[#1E2738] flex flex-col">
            <div className="flex-1 relative pb-[150px] md:pb-[200px]">
                <div className="container mx-auto px-4 sm:px-6">
                    <nav className="flex items-center justify-between py-4 sm:py-6">
                        <div className="w-24 sm:w-32 p-1">
                            <img
                                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Group%201526-VPlaiFHAVSxM2rng9TkI4tpfSvx0Ee.png"
                                alt="Klickie Logo"
                                width={128}
                                height={40}
                            />
                        </div>
                    </nav>

                    <div className="max-w-5xl mx-auto flex flex-col">
                        <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 items-start py-8 sm:py-12">
                            <div className="px-0 sm:px-4 max-w-2xl">
                                <h1 className="text-[#FFD700] text-3xl sm:text-4xl md:text-5xl leading-none" style={{ fontFamily: "'Klickie Font', Arial, sans-serif" }}>
                                    BOOK ANYONE
                                    <span className="block">WITH YOUR PHONE NUMBER</span>
                                </h1>
                            </div>

                            <div className="flex flex-col gap-4 md:gap-6 items-center md:items-start px-4 sm:px-8">
                                <div className="self-end md:self-end max-w-[85%] md:max-w-[90%]">
                                    <div className="bg-[#FFD700] text-[#1E2738] px-5 sm:px-6 py-3 sm:py-4 rounded-2xl rounded-tr-none text-base sm:text-lg">
                                        When do you have time?
                                        <div className="text-[#1E2738]/60 text-xs text-right mt-1">12:00</div>
                                    </div>
                                </div>
                                <div className="self-start md:self-start max-w-[85%] md:max-w-[90%]">
                                    <div className="bg-[#4895AA] text-white px-5 sm:px-6 py-3 sm:py-4 rounded-2xl rounded-tl-none">
                                        <div className="text-xs mb-1 text-white/80">Customer</div>
                                        <div className="text-base sm:text-lg">Pls check my klickie!</div>
                                        <div className="text-white/60 text-xs text-right mt-1">12:01</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="absolute bottom-20 md:bottom-24 left-0 right-0 z-20">
                    <div className="max-w-md mx-auto px-4">
                        <PhoneInput
                            country={country}
                            setCountry={setCountry}
                            phoneNumber={phoneNumber}
                            handlePhoneChange={(e) => handlePhoneChange(e, false)}
                            isValid={isValid}
                            handleSubmit={() => handleSubmit(false)}
                        />
                    </div>
                </div>
            </div>

            <div className="w-full">
                <div className="bg-[#FF5A5F] py-12 sm:py-16 text-center relative z-10">
                    <div className="container mx-auto px-4">
                        <h2 className="text-white text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6" style={{ fontFamily: "'Klickie Font', Arial, sans-serif" }}>
                            NO HASSLE, NO EXTRA APPS, NO PROBLEMO
                        </h2>
                        <p className="text-white text-lg sm:text-xl max-w-2xl mx-auto uppercase">
                            SHARE YOUR KLICKIE AND LET CLIENTS BOOK YOU DIRECTLY ON WHATSAPP
                        </p>
                    </div>
                </div>

                <footer className="bg-[#1A202C] py-8 text-center">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
                            <div className="text-sm text-gray-400">
                                © 2024 Klickie. All rights reserved.
                            </div>
                            <div className="flex gap-6">
                                <button
                                    onClick={() => {/* handle click */}}
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    Privacy Policy
                                </button>
                                <button
                                    onClick={() => {/* handle click */}}
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    Terms of Service
                                </button>
                                <button
                                    onClick={() => {/* handle click */}}
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    Contact
                                </button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}
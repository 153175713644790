import React from 'react';
import { countries } from '../utils/countries';

interface PhoneInputProps {
  country: string;
  setCountry: (country: string) => void;
  phoneNumber: string;
  handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isValid: boolean;
  handleSubmit: () => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  country,
  setCountry,
  phoneNumber,
  handlePhoneChange,
  isValid,
  handleSubmit
}) => {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-gray-300 mb-2">Phone Number</label>
        <div className="relative rounded-xl border border-black p-0.5 bg-gradient-to-r from-[#4895AA] to-[#4895AA]">
          <div className="absolute -bottom-1 -right-1 w-[calc(100%-2px)] h-[calc(100%-2px)] bg-[#FCBF11] rounded-xl -z-10"></div>
          <div className="absolute -bottom-1.5 -right-1.5 w-[calc(100%+2px)] h-[calc(100%+2px)] bg-[#FCBF11] rounded-xl -z-20"></div>
          <div className="flex bg-white rounded-[10px] border border-black overflow-hidden h-[50px]">
            <select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="w-24 h-[50px] px-2 text-center border-r border-black rounded-none focus:ring-0 bg-transparent"
              style={{
                fontFamily: "'Good Headline Pro Cond', sans-serif",
                fontSize: '20px'
              }}
            >
              {countries.map((c) => (
                <option key={c.code} value={c.code}>
                  {c.code} ({c.prefix})
                </option>
              ))}
            </select>
            <input
              type="tel"
              value={phoneNumber}
              onChange={handlePhoneChange}
              placeholder="123456789"
              className="w-full h-[50px] px-4 text-left border-0 rounded-none focus:ring-0 bg-transparent"
              style={{
                fontFamily: "'Good Headline Pro Cond', sans-serif",
                fontSize: '20px'
              }}
            />
          </div>
        </div>
      </div>
      <button
        onClick={handleSubmit}
        disabled={!isValid}
        className={`w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold transition-colors ${
          isValid ? 'hover:bg-[#4895AA]/90' : 'opacity-50 cursor-not-allowed'
        }`}
      >
        Continue
      </button>
    </div>
  );
};

export default PhoneInput;
import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:8080' 
  : process.env.REACT_APP_API_URL || 'https://api.klickie.me';

export interface Country {
  code: string;
  prefix: string;
}

class AuthService {
  normalizePhoneNumber(phoneNumber: string, country: Country): string {
    const cleanPhoneNumber = phoneNumber.replace(/\D/g, '');
    return `${country.prefix}${cleanPhoneNumber}`;
  }

  async requestVerificationCode(phoneNumber: string, country: Country, type: 'customer' | 'staff' = 'customer'): Promise<void> {
    const fullPhoneNumber = this.normalizePhoneNumber(phoneNumber, country);
    
    const endpoint = type === 'customer' ? '/api/verify-customer' : '/api/request-verification';
    
    await axios.post(`${API_URL}${endpoint}`, { 
      phoneNumber: fullPhoneNumber
    });
  }

  async verifyCode(phoneNumber: string, country: Country, code: string, type: 'customer' | 'staff' = 'customer'): Promise<any> {
    const fullPhoneNumber = this.normalizePhoneNumber(phoneNumber, country);
    
    const endpoint = type === 'customer' ? '/api/verify-code' : '/api/verify-staff';
    
    const response = await axios.post(`${API_URL}${endpoint}`, {
      phoneNumber: fullPhoneNumber,
      code: code.toString()
    });

    return response.data;
  }

  async requestConnection(proId: string, customerPhone: string, country: Country): Promise<void> {
    const fullPhoneNumber = this.normalizePhoneNumber(customerPhone, country);
    
    await axios.post(`${API_URL}/api/request-connection`, {
      proId: proId.startsWith('+') ? proId : `+${proId}`,
      customerPhone: fullPhoneNumber
    });
  }
}

export default new AuthService(); 
import { FullStory } from '@fullstory/browser';

export const initFullStory = (orgId: string): void => {
  if (typeof window !== 'undefined') {
    (window as any)._fs_host = 'fullstory.com';
    (window as any)._fs_script = 'edge.fullstory.com/s/fs.js';
    (window as any)._fs_org = orgId;
    (window as any)._fs_namespace = 'FS';

    // Load FullStory script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://${(window as any)._fs_script}`;
    document.head.appendChild(script);
  }
};

type UserData = {
  id: string;
  email?: string;
  name?: string;
  userType: 'pro' | 'client';
};

export const identifyUser = (userId: string, userInfo: Record<string, any>): void => {
  if (typeof window !== 'undefined' && (window as any).FS) {
    (window as any).FS.identify(userId, userInfo);
  }
};

export const trackEvent = (eventName: string, eventProperties: Record<string, any>): void => {
  if (typeof window !== 'undefined' && (window as any).FS) {
    (window as any).FS.event(eventName, eventProperties);
  }
};

export const isFullStoryInitialized = (): boolean => {
  return typeof window !== 'undefined' && !!(window as any).FS;
}; 
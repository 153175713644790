import React, { useState } from 'react';
import PhoneInput from './PhoneInput';
import { countries } from '../utils/countries';

interface RequestAccessModalProps {
  onSubmit: (phoneNumber: string) => void;
  onClose: () => void;
  onExistingCustomer: () => void;
}

export default function RequestAccessModal({ onSubmit, onClose, onExistingCustomer }: RequestAccessModalProps) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('BE');
  const [isValid, setIsValid] = useState(false);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-[#1E2738] rounded-3xl p-8 max-w-md w-full relative">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          ✕
        </button>

        <h2 className="text-2xl font-bold text-[#FFD700] mb-6 text-center">
          Continue with Klickie
        </h2>

        <p className="text-gray-300 mb-6 text-center">
          To continue, please let us know if you've used Klickie before:
        </p>

        <div className="space-y-4">
          <button
            onClick={onExistingCustomer}
            className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors"
          >
            I'm already a customer
          </button>
          <button
            onClick={() => {
              // Show phone input for new users
              const phoneInput = document.getElementById('phone-input');
              if (phoneInput) {
                phoneInput.style.display = 'block';
              }
            }}
            className="w-full bg-[#4895AA]/20 text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/30 transition-colors"
          >
            I'm new here
          </button>

          <div id="phone-input" style={{ display: 'none' }}>
            <p className="text-gray-300 mb-4">
              Enter your phone number and we'll notify them.
            </p>
            <PhoneInput
              country={country}
              setCountry={setCountry}
              phoneNumber={phoneNumber}
              handlePhoneChange={(e) => {
                setPhoneNumber(e.target.value);
                setIsValid(e.target.value.length > 0);
              }}
              isValid={isValid}
              handleSubmit={() => onSubmit(phoneNumber)}
            />
          </div>
        </div>
      </div>
    </div>
  );
} 
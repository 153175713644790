import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Calendar, Clock } from 'lucide-react';
import klickieLogo from '../assets/klickie-logo.png';

const API_URL = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8080' 
    : process.env.REACT_APP_API_URL || 'https://api.klickie.me';

interface Booking {
    id: string;
    startTime: string;
    endTime: string;
    status: string;
    activity?: string;
    customerName?: string;
    clientId?: string;
    proId?: string;
    type: 'received' | 'made';
    notes?: string;
}

export default function Bookings(): JSX.Element {
    const { userId } = useParams();
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<'upcoming' | 'past'>('upcoming');

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await fetch(`${API_URL}/api/bookings/${userId}`);
                if (!response.ok) throw new Error('Failed to fetch bookings');
                const data = await response.json();
                setBookings(data);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to load bookings');
            } finally {
                setLoading(false);
            }
        };

        fetchBookings();
    }, [userId]);

    const formatBookingTime = (booking: Booking) => {
        const start = new Date(booking.startTime);
        const end = new Date(booking.endTime);
        return `${start.toLocaleString([], {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })} - ${end.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        })}`;
    };

    const now = new Date();
    const upcomingBookings = bookings.filter(booking => new Date(booking.startTime) > now);
    const pastBookings = bookings.filter(booking => new Date(booking.startTime) <= now);

    const BookingCard = ({ booking }: { booking: Booking }) => {
        return (
            <div className="bg-white/10 rounded-xl p-4 mb-4">
                <div className="flex items-start justify-between">
                    <div className="flex-1">
                        <div className="flex items-center space-x-2 mb-2">
                            <Calendar className="w-4 h-4 text-[#FFD700]" />
                            <span className="text-white font-medium">
                                {formatBookingTime(booking)}
                            </span>
                        </div>
                        {booking.activity && (
                            <div className="text-white/80 mb-1">
                                Activity: {booking.activity}
                            </div>
                        )}
                        {booking.type === 'received' && booking.customerName && (
                            <div className="text-white/80 mb-1">
                                Booked by: {booking.customerName}
                            </div>
                        )}
                        {booking.notes && (
                            <div className="text-white/80 mb-1">
                                Notes: {booking.notes}
                            </div>
                        )}
                        <div className="text-sm text-white/60">
                            Status: {booking.status}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-[#1E2738] flex flex-col items-center p-4">
            <div className="w-full max-w-md space-y-6 py-12">
                {/* Logo and Title */}
                <div className="flex flex-col items-center mb-8">
                    <div className="relative w-32 h-32 mb-6">
                        <div className="absolute inset-0 bg-[#FCBF11] rounded-full -z-10 transform scale-110"></div>
                        <img 
                            src={klickieLogo}
                            alt="Klickie"
                            className="absolute inset-0 w-full h-full object-contain rounded-full z-10"
                        />
                    </div>
                    <h1 className="text-[#FFD700] text-4xl text-center font-['Klickie_Font',_Arial,_sans-serif] mb-4">
                        Your Bookings
                    </h1>
                </div>

                {/* Tab Buttons */}
                <div className="flex space-x-4 mb-6">
                    <button
                        onClick={() => setActiveTab('upcoming')}
                        className={`flex-1 py-2 px-4 rounded-xl font-semibold ${
                            activeTab === 'upcoming'
                                ? 'bg-[#4895AA] text-white'
                                : 'bg-white/10 text-white/60'
                        }`}
                    >
                        Upcoming
                    </button>
                    <button
                        onClick={() => setActiveTab('past')}
                        className={`flex-1 py-2 px-4 rounded-xl font-semibold ${
                            activeTab === 'past'
                                ? 'bg-[#4895AA] text-white'
                                : 'bg-white/10 text-white/60'
                        }`}
                    >
                        Past
                    </button>
                </div>

                {/* Bookings List */}
                {loading ? (
                    <div className="text-white text-center">Loading...</div>
                ) : error ? (
                    <div className="text-red-500 text-center">{error}</div>
                ) : (
                    <div className="space-y-4">
                        {(activeTab === 'upcoming' ? upcomingBookings : pastBookings).map((booking) => (
                            <BookingCard key={booking.id} booking={booking} />
                        ))}
                        {(activeTab === 'upcoming' ? upcomingBookings : pastBookings).length === 0 && (
                            <div className="text-white/60 text-center">
                                No {activeTab} bookings found
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
} 
import React, { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Minus, Plus } from 'lucide-react'

interface Service {
    service_name: string
    service_type: 'Private'
    price: number
    duration: number
    quota: number
    frequency: 'One-time' | 'Recurring'
}

const InputWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="relative mb-4">
    <div className="absolute inset-0 bg-white rounded-xl z-10"></div>
    <div className="absolute inset-0 border-2 border-[#4895AA] rounded-xl z-20"></div>
    <div className="absolute -right-[4px] -bottom-[4px] left-0 top-0 bg-[#FCBF11] rounded-xl z-0 translate-y-[2px] translate-x-[2px]"></div>
    {children}
  </div>
)

// Add predefined activities
const PREDEFINED_ACTIVITIES = [
    'Dog Walker',
    'Babysitter',
    'Padel Teacher',
    'Lawyer',
    'Plumber',
    'Electrician',
    'Tennis Teacher',
    'Piano Teacher',
    'Personal Trainer',
    'Yoga Instructor',
    'Language Teacher',
    'Math Tutor',
    'Swimming Instructor',
    'Life Coach',
    'Massage Therapist',
    'Guitar Teacher',
    'Dance Instructor',
    'Football Coach',
    'Basketball Coach',
    'Fitness Trainer',
    'Nutritionist',
    'Golf Instructor',
    'Boxing Coach',
    'Meditation Guide',
    'Art Teacher'
];

export default function OnboardingPro(): JSX.Element {
    const { userId } = useParams() as { userId: string };
    const [activity, setActivity] = useState('')
    const [filteredActivities, setFilteredActivities] = useState<string[]>([])
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [profileName, setProfileName] = useState('')
    const [errors, setErrors] = useState<string[]>([])
    const [services, setServices] = useState<Service[]>([{
        service_name: '',
        service_type: 'Private',
        price: 0,
        duration: 60,
        quota: 1,
        frequency: '' as 'One-time' | 'Recurring'
    }])

    useEffect(() => {
        if (activity) {
            setProfileName(`${activity.charAt(0).toUpperCase() + activity.slice(1)} lessons`)
        }
    }, [activity])

    // Handle activity input change
    const handleActivityChange = (input: string) => {
        setActivity(input);
        if (input.length > 0) {
            const filtered = PREDEFINED_ACTIVITIES.filter(act => 
                act.toLowerCase().includes(input.toLowerCase())
            );
            setFilteredActivities(filtered);
            setShowSuggestions(true);
        } else {
            setFilteredActivities([]);
            setShowSuggestions(false);
        }
    };

    // Handle selecting an activity from suggestions
    const handleActivitySelect = (selectedActivity: string) => {
        setActivity(selectedActivity);
        setShowSuggestions(false);
        setProfileName(`${selectedActivity} lessons`);
    };

    const validateForm = () => {
        const newErrors: string[] = [];
        if (!activity.trim()) {
            newErrors.push("Please enter your activity");
        }
        services.forEach((service, index) => {
            if (!service.service_name.trim()) {
                newErrors.push(`Service ${index + 1}: Please enter a service name`);
            }
            if (service.price <= 0) {
                newErrors.push(`Service ${index + 1}: Please enter a valid price`);
            }
            if (service.duration <= 0) {
                newErrors.push(`Service ${index + 1}: Please enter a valid duration`);
            }
        });
        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const handleSubmit = async () => {
        try {
            if (!validateForm()) {
                alert('Please fill in all required fields');
                return;
            }

            const API_URL = process.env.NODE_ENV === 'development' 
                ? 'http://localhost:8080'  // or whatever port your local server uses
                : process.env.REACT_APP_API_URL || 'https://api.klickie.me';
                
            console.log('Using API URL:', API_URL);

            const profileData = {
                userId,
                services,
                activity,
                profileName
            };
            console.log('Submitting profile data:', JSON.stringify(profileData, null, 2));

            const profileResponse = await axios.post(`${API_URL}/api/profile`, profileData);
            console.log('Profile response:', profileResponse.data);

            if (!profileResponse.data.success) {
                throw new Error(profileResponse.data.error || 'Failed to save profile');
            }

            window.location.href = 'https://api.whatsapp.com/send/?phone=3197010257400&type=phone_number&app_absent=0';
        } catch (error: any) {
            console.error('Error submitting form:', error);
            alert(error.response?.data?.error || 'Error saving profile. Please try again.');
        }
    };

    const handleServiceChange = (index: number, field: keyof Service, value: string | number) => {
        const updatedServices = [...services]
        updatedServices[index] = { ...updatedServices[index], [field]: value }
        setServices(updatedServices)
    }

    const addService = () => {
        setServices([...services, {
            service_name: '',
            service_type: 'Private',
            price: 0,
            duration: 60,
            quota: 1,
            frequency: '' as 'One-time' | 'Recurring'
        }])
    }

    const removeService = (index: number) => {
        if (services.length > 1) {
            setServices(services.filter((_, i) => i !== index))
        }
    }

    return (
        <div className="min-h-screen bg-[#1E2738] flex flex-col items-center p-4">
            <div className="w-full max-w-md space-y-6 py-12">
                <h1 className="text-[#FFD700] text-4xl mb-8 font-['Klickie_Font',_Arial,_sans-serif] text-center">
                    Let's get you started
                </h1>

                {/* Activity Input with Autocomplete */}
                <InputWrapper>
                    <div className="relative">
                        <input
                            type="text"
                            value={activity}
                            onChange={(e) => handleActivityChange(e.target.value)}
                            placeholder="What's your activity? (e.g., Padel lessons)"
                            className="relative z-30 w-full h-[50px] px-4 text-left border-0 rounded-xl focus:ring-0 bg-transparent font-['Good_Headline_Pro_Cond',_sans-serif] text-xl"
                        />
                        {showSuggestions && filteredActivities.length > 0 && (
                            <div className="absolute z-40 w-full mt-1 bg-white rounded-xl shadow-lg max-h-60 overflow-auto">
                                {filteredActivities.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-700"
                                        onClick={() => handleActivitySelect(suggestion)}
                                    >
                                        {suggestion}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </InputWrapper>

                {services.map((service, index) => (
                    <div key={index} className="bg-[#1A202C] p-4 rounded-xl space-y-4">
                        <div className="flex items-center justify-between">
                            <h2 className="text-white text-xl font-['Good_Headline_Pro_Cond',_sans-serif]">
                                Service {index + 1}
                            </h2>
                            {index > 0 && (
                                <button
                                    onClick={() => removeService(index)}
                                    className="text-white hover:text-red-500"
                                >
                                    <Minus className="h-5 w-5" />
                                </button>
                            )}
                        </div>

                        <InputWrapper>
                            <input
                                type="text"
                                value={service.service_name}
                                onChange={(e) => handleServiceChange(index, 'service_name', e.target.value)}
                                placeholder="Service name (e.g., Private Padel Lesson)"
                                className="relative z-30 w-full h-[50px] px-4 text-left border-0 rounded-xl focus:ring-0 bg-transparent font-['Good_Headline_Pro_Cond',_sans-serif] text-xl"
                            />
                        </InputWrapper>

                        <div className="grid grid-cols-1 gap-4">
                            <InputWrapper>
                                <div className="relative flex items-center bg-transparent rounded-xl">
                                    <input
                                        type="number"
                                        value={service.price || ''}
                                        onChange={(e) => handleServiceChange(index, 'price', parseFloat(e.target.value) || 0)}
                                        placeholder="Price"
                                        className="relative z-30 w-full h-[50px] pl-4 pr-8 text-left border-0 rounded-xl focus:ring-0 bg-transparent font-['Good_Headline_Pro_Cond',_sans-serif] text-xl"
                                    />
                                    <span className="absolute right-4 text-gray-500 font-['Good_Headline_Pro_Cond',_sans-serif] text-xl z-40">€</span>
                                </div>
                            </InputWrapper>

                            <InputWrapper>
                                <div className="relative flex items-center bg-transparent rounded-xl">
                                    <input
                                        type="number"
                                        value={service.duration || ''}
                                        onChange={(e) => handleServiceChange(index, 'duration', parseInt(e.target.value) || 60)}
                                        placeholder="Duration"
                                        className="relative z-30 w-full h-[50px] pl-4 pr-8 text-left border-0 rounded-xl focus:ring-0 bg-transparent font-['Good_Headline_Pro_Cond',_sans-serif] text-xl"
                                    />
                                    <span className="absolute right-4 text-gray-500 font-['Good_Headline_Pro_Cond',_sans-serif] text-xl z-40">min</span>
                                </div>
                            </InputWrapper>
                        </div>

                        <div className="relative">
                            <div className="mb-2 text-gray-400 text-sm">
                                Choose how clients can book your service. You can offer one-time sessions or recurring appointments (coming soon).
                            </div>
                            <select
                                value={service.frequency}
                                onChange={(e) => handleServiceChange(index, 'frequency', e.target.value)}
                                className="w-full h-[50px] px-4 text-left border-0 rounded-xl focus:ring-0 bg-white font-['Good_Headline_Pro_Cond',_sans-serif] text-xl appearance-none"
                            >
                                <option value="" disabled>Choose how clients can book this service</option>
                                <option value="One-time">One-time Session - Clients book individual appointments</option>
                                <option value="Recurring" disabled>Recurring Sessions - Clients book regular weekly/monthly appointments (Coming Soon)</option>
                            </select>
                        </div>
                    </div>
                ))}

                <button
                    onClick={addService}
                    className="w-full h-[50px] bg-[#4895AA] hover:bg-[#4895AA]/90 text-white rounded-xl font-['Good_Headline_Pro_Cond',_sans-serif] text-xl flex items-center justify-center"
                >
                    <Plus className="h-5 w-5 mr-2" />
                    Add a service
                </button>

                <button
                    onClick={handleSubmit}
                    type="submit"
                    style={{
                        width: '100%',
                        height: '50px',
                        backgroundColor: '#FFD700',
                        color: 'black',
                        borderRadius: '0.75rem',
                        fontFamily: "'Good Headline Pro Cond', sans-serif",
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    Confirm and go to whatsapp
                </button>

                {errors.length > 0 && (
                    <div className="text-[#FF5A5F] text-sm space-y-1">
                        {errors.map((error, index) => (
                            <div key={index}>{error}</div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
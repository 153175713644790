const countries = [
    { code: 'BE', prefix: '+32', name: 'Belgium', flag: '🇧🇪' },
    { code: 'NL', prefix: '+31', name: 'Netherlands', flag: '🇳🇱' },
    { code: 'FR', prefix: '+33', name: 'France', flag: '🇫🇷' },
    { code: 'DE', prefix: '+49', name: 'Germany', flag: '🇩🇪' },
    { code: 'GB', prefix: '+44', name: 'United Kingdom', flag: '🇬🇧' },
    { code: 'ES', prefix: '+34', name: 'Spain', flag: '🇪🇸' },
    { code: 'IT', prefix: '+39', name: 'Italy', flag: '🇮🇹' },
    { code: 'LU', prefix: '+352', name: 'Luxembourg', flag: '🇱🇺' },
    { code: 'US', prefix: '+1', name: 'United States', flag: '🇺🇸' },
    { code: 'IN', prefix: '+91', name: 'India', flag: 'in' },
]

export default countries
import React from 'react';
import { useParams } from 'react-router-dom';
import { Users, BookOpen, XCircle, BellRing, MessageCircle } from 'lucide-react';
import klickieLogo from '../assets/klickie-logo.png';

interface ActionButton {
    icon: React.ReactNode;
    title: string;
    description: string;
    action: () => void;
}

export default function WhatNowCustomer(): JSX.Element {
    const { userId } = useParams();

    const actionButtons: ActionButton[] = [
        {
            icon: <Users className="w-6 h-6" />,
            title: "MY KLICKIES",
            description: "View all your connected pros and their services",
            action: () => window.location.href = `/network/${userId}`
        },
        {
            icon: <BookOpen className="w-6 h-6" />,
            title: "MY BOOKINGS",
            description: "Check your upcoming appointments and booking history",
            action: () => window.location.href = `/bookings/${userId}`
        },
        {
            icon: <XCircle className="w-6 h-6" />,
            title: "CANCELLATIONS",
            description: "View and manage your booking cancellations",
            action: () => window.location.href = `/cancellations/${userId}`
        },
        {
            icon: <BellRing className="w-6 h-6" />,
            title: "CALLBACKS",
            description: "View your pending callback requests from pros",
            action: () => window.location.href = `/callbacks/${userId}`
        },
        {
            icon: <MessageCircle className="w-6 h-6" />,
            title: "SUPPORT",
            description: "Get help or contact our support team",
            action: () => window.location.href = `mailto:support@klickie.me`
        }
    ];

    return (
        <div className="min-h-screen bg-[#1E2738] text-white p-8">
            <div className="max-w-2xl mx-auto">
                {/* Header */}
                <div className="text-center mb-12">
                    <div className="w-32 h-32 mx-auto relative mb-6">
                        <div className="absolute inset-0 bg-[#4895AA] rounded-full -z-10 transform scale-110"></div>
                        <img 
                            src={klickieLogo}
                            alt="Klickie"
                            className="absolute inset-0 w-full h-full object-contain rounded-full z-10"
                        />
                    </div>
                    <h1 className="text-4xl font-bold mb-4">Welcome to Your Klickie Hub!</h1>
                    <p className="text-gray-300 text-lg mb-8">
                        Your one-stop place to manage all your service bookings and pro connections.
                        Get started by asking your favorite pros to share their Klickie with you!
                    </p>
                    <div className="bg-[#4895AA]/20 p-6 rounded-xl mb-8">
                        <h2 className="text-xl font-semibold mb-3">🎯 Getting Started:</h2>
                        <ul className="text-left space-y-2 text-gray-300">
                            <li>1. Ask your service provider to share their Klickie with you</li>
                            <li>2. Once shared, you'll find them in "My Klickies"</li>
                            <li>3. Click on any Klickie to view services and book appointments</li>
                            <li>4. Manage your bookings and get notifications through WhatsApp</li>
                            <li>5. Request callbacks when you need quick responses</li>
                        </ul>
                    </div>
                    <div className="bg-[#4895AA]/20 p-6 rounded-xl mb-8">
                        <h2 className="text-xl font-semibold mb-3">💡 Pro Tip:</h2>
                        <p className="text-gray-300">
                            Don't see your favorite service provider? Just ask them to share their Klickie with you
                            through WhatsApp. It's the easiest way to connect and book their services!
                        </p>
                    </div>
                </div>

                {/* Action Buttons */}
                <div className="grid gap-4">
                    {actionButtons.map((button, index) => (
                        <button
                            key={index}
                            onClick={button.action}
                            className="w-full relative group"
                        >
                            <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                            <div className="relative w-full bg-[#4895AA] text-white py-4 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                <div className="flex items-start space-x-4">
                                    <div className="bg-white/10 p-3 rounded-lg">
                                        {button.icon}
                                    </div>
                                    <div className="text-left flex-1">
                                        <div className="font-bold text-lg">{button.title}</div>
                                        <div className="text-gray-300 text-sm">{button.description}</div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
} 
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import klickieLogo from '../assets/klickie-logo.png';
import { Search, Filter } from 'lucide-react';

interface Pro {
    userId: string;
    name: string;
    activity?: string;
    photoUrl?: string;
    services?: Array<{
        service_name: string;
        price: string;
        duration: string;
    }>;
}

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

export default function Network(): JSX.Element {
    const { userId } = useParams();
    const [pros, setPros] = useState<Pro[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [activityFilter, setActivityFilter] = useState<string>('all');

    useEffect(() => {
        const fetchNetwork = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/network/${userId}`);
                setPros(response.data);
            } catch (error) {
                console.error('Error fetching network:', error);
                setError('Failed to load your network');
            } finally {
                setLoading(false);
            }
        };

        fetchNetwork();
    }, [userId]);

    const filteredPros = pros.filter(pro => {
        const matchesSearch = pro.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            pro.activity?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            pro.services?.some(s => s.service_name.toLowerCase().includes(searchTerm.toLowerCase()));
        
        const matchesActivity = activityFilter === 'all' || pro.activity === activityFilter;
        
        return matchesSearch && matchesActivity;
    });

    const uniqueActivities = ['all', ...pros.reduce<string[]>((acc, pro) => {
        if (pro.activity && !acc.includes(pro.activity)) {
            acc.push(pro.activity);
        }
        return acc;
    }, [])];

    if (loading) return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
    if (error) return <div className="flex justify-center items-center min-h-screen text-red-500">{error}</div>;

    return (
        <div className="min-h-screen bg-[#1E2738] text-white p-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold mb-8 text-center">Your Klickie Network</h1>

                {pros.length === 0 ? (
                    <div className="text-center bg-[#4895AA]/20 p-8 rounded-xl">
                        <h2 className="text-2xl font-semibold mb-4">No Klickies Yet!</h2>
                        <p className="text-gray-300 mb-6">
                            You haven't connected with any pros yet. Ask your service providers to share their Klickie with you!
                        </p>
                        <div className="bg-yellow-500/10 p-4 rounded-lg inline-block">
                            <p className="text-sm">
                                💡 Tip: When a pro shares their Klickie with you, they'll appear here automatically.
                            </p>
                        </div>
                    </div>
                ) : (
                    <>
                        {/* Search and Filter */}
                        <div className="mb-8 flex flex-col md:flex-row gap-4">
                            <div className="relative flex-1">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                <input
                                    type="text"
                                    placeholder="Search by name, activity, or service..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="w-full bg-[#4895AA]/20 text-white pl-12 pr-4 py-3 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#FCBF11]"
                                />
                            </div>
                            <div className="relative">
                                <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                <select
                                    value={activityFilter}
                                    onChange={(e) => setActivityFilter(e.target.value)}
                                    className="bg-[#4895AA]/20 text-white pl-12 pr-4 py-3 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#FCBF11] appearance-none"
                                >
                                    {uniqueActivities.map(activity => (
                                        <option key={activity} value={activity}>
                                            {activity === 'all' ? 'All Activities' : activity}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Pros Grid */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {filteredPros.map((pro) => (
                                <button
                                    key={pro.userId}
                                    onClick={() => window.location.href = `/k/${pro.userId}`}
                                    className="bg-[#4895AA]/10 p-6 rounded-xl hover:bg-[#4895AA]/20 transition-all relative group"
                                >
                                    <div className="absolute inset-0 bg-[#FCBF11]/5 rounded-xl translate-x-1 translate-y-1 -z-10"></div>
                                    <div className="flex items-start space-x-4">
                                        <div className="w-16 h-16 relative">
                                            <div className="absolute inset-0 bg-[#FCBF11] rounded-full -z-10 transform scale-110"></div>
                                            <img
                                                src={pro.photoUrl || klickieLogo}
                                                alt={pro.name}
                                                className="w-full h-full object-cover rounded-full"
                                                onError={(e) => {
                                                    const target = e.target as HTMLImageElement;
                                                    target.src = klickieLogo;
                                                }}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="text-xl font-semibold">{pro.name}</h3>
                                            {pro.activity && (
                                                <p className="text-gray-300 mb-2">{pro.activity}</p>
                                            )}
                                            {pro.services && pro.services.length > 0 && (
                                                <div className="space-y-1">
                                                    {pro.services.map((service, index) => (
                                                        <p key={index} className="text-sm text-gray-400">
                                                            {service.service_name} - €{service.price}
                                                        </p>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
} 
import React, { useState } from 'react';
import authService, { Country } from '../services/authService';
import { countries } from '../utils/countries';
import PhoneInput from './PhoneInput';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

interface LoginModalProps {
  onLogin: (userId: string) => void;
  onClose: () => void;
  isStaffLogin?: boolean;
}

export default function LoginModal({ onLogin, onClose, isStaffLogin = false }: LoginModalProps) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('BE');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [isExistingCustomer, setIsExistingCustomer] = useState<boolean | null>(isStaffLogin ? true : null);
  const [error, setError] = useState<string | null>(null);

  const formatPhoneNumber = (phone: string, countryCode: string) => {
    // Remove all non-digits
    const cleanPhone = phone.replace(/\D/g, '');
    // Remove leading 0 if present
    const withoutLeadingZero = cleanPhone.startsWith('0') ? cleanPhone.substring(1) : cleanPhone;
    // Get country prefix (e.g., "32" for BE)
    const selectedCountry = countries.find(c => c.code === countryCode);
    const prefix = selectedCountry?.prefix.replace('+', '') || '';
    // Return formatted number with + and country code
    return `+${prefix}${withoutLeadingZero}`;
  };

  const handleSubmitPhone = async () => {
    if (!phoneNumber) return;
    setError(null);
    
    try {
      const selectedCountry = countries.find(c => c.code === country);
      if (!selectedCountry) {
        setError('Invalid country selected');
        return;
      }

      const formattedPhone = formatPhoneNumber(phoneNumber, country);

      if (isStaffLogin) {
        // Staff verification flow
        try {
          const response = await axios.post(`${API_URL}/api/verify-staff-status`, {
            phoneNumber: formattedPhone
          });
          
          if (response.data.success) {
            setShowVerification(true);
          } else {
            setError(response.data.error || 'Failed to send verification code');
          }
        } catch (err: any) {
          console.error('Error sending staff verification:', err);
          setError(err.response?.data?.error || 'Failed to send verification code');
        }
      } else if (isExistingCustomer) {
        // Send verification code for existing customers
        try {
          await authService.requestVerificationCode(phoneNumber, selectedCountry);
          setShowVerification(true);
        } catch (err: any) {
          if (err.response?.status === 404) {
            setError('Phone number not found. Please select "I\'m new here" if you haven\'t used Klickie before.');
          } else {
            setError('Failed to send verification code');
          }
        }
      } else {
        // For new users, send request to pro
        try {
          const proId = window.location.pathname.split('/')[1];
          await authService.requestConnection(proId, phoneNumber, selectedCountry);
          alert("Request sent! The professional will share their Klickie with you via WhatsApp.");
          onClose();
        } catch (err: any) {
          console.error('Error sending request:', err);
          setError(err.response?.data?.error || 'Failed to send request to professional');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An unexpected error occurred');
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) return;
    setError(null);

    try {
      const selectedCountry = countries.find(c => c.code === country);
      if (!selectedCountry) {
        setError('Invalid country selected');
        return;
      }

      const formattedPhone = formatPhoneNumber(phoneNumber, country);

      if (isStaffLogin) {
        // Staff verification flow
        try {
          const response = await axios.post(`${API_URL}/api/verify-staff-status`, {
            phoneNumber: formattedPhone,
            code: verificationCode
          });
          
          if (response.data.success && response.data.isStaff) {
            // Store session token and user data
            if (response.data.sessionToken) {
              localStorage.setItem('sessionToken', response.data.sessionToken);
            }
            if (response.data.user) {
              localStorage.setItem('userData', JSON.stringify(response.data.user));
            }
            // Only call onLogin if both session token and user data are stored
            if (response.data.sessionToken && response.data.user) {
              onLogin(formattedPhone);
              onClose();
            } else {
              setError('Invalid login response. Please try again.');
            }
          } else {
            setError(response.data.error || 'Not authorized as staff');
          }
        } catch (err: any) {
          console.error('Error verifying staff code:', err);
          setError(err.response?.data?.error || 'Failed to verify code');
        }
      } else {
        // Regular customer verification flow
        const response = await authService.verifyCode(phoneNumber, selectedCountry, verificationCode);
        if (response.success) {
          const fullPhoneNumber = authService.normalizePhoneNumber(phoneNumber, selectedCountry);
          localStorage.setItem('userId', fullPhoneNumber);
          onLogin(fullPhoneNumber);
          onClose();
        }
      }
    } catch (err) {
      setError('Invalid verification code');
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-[#1E2738] rounded-3xl p-8 max-w-md w-full relative">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          ✕
        </button>

        <h2 className="text-2xl font-bold text-[#FFD700] mb-6 text-center">
          {isStaffLogin ? "Staff Login" : "Continue with Klickie"}
        </h2>

        {error && (
          <div className="mb-4 p-3 bg-red-500/10 border border-red-500 rounded-xl text-red-500 text-sm">
            {error}
          </div>
        )}

        {!isStaffLogin && isExistingCustomer === null ? (
          <>
            <p className="text-gray-300 mb-6 text-center">
              To continue, please let us know if you've used Klickie before:
            </p>
            <div className="space-y-4">
              <button
                onClick={() => setIsExistingCustomer(true)}
                className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors"
              >
                I'm already a customer
              </button>
              <button
                onClick={() => setIsExistingCustomer(false)}
                className="w-full bg-[#4895AA]/20 text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/30 transition-colors"
              >
                I'm new here
              </button>
            </div>
          </>
        ) : (
          <div className="space-y-4">
            {!showVerification ? (
              <>
                <p className="text-gray-300 mb-4 text-center">
                  {isStaffLogin 
                    ? "Please enter your staff phone number to login."
                    : isExistingCustomer 
                      ? "Welcome back! Please enter your phone number to login."
                      : "Great! Enter your phone number and we'll help you get connected."}
                </p>
                <div>
                  <PhoneInput
                    country={country}
                    setCountry={setCountry}
                    phoneNumber={phoneNumber}
                    handlePhoneChange={(e) => setPhoneNumber(e.target.value)}
                    isValid={phoneNumber.length > 0}
                    handleSubmit={handleSubmitPhone}
                  />
                  <p className="text-gray-400 text-sm mt-2">
                    {isStaffLogin || isExistingCustomer 
                      ? "We'll send a verification code to your WhatsApp"
                      : "We'll notify the professional to share their Klickie with you"}
                  </p>
                </div>
              </>
            ) : (
              <>
                <p className="text-gray-300 mb-4 text-center">
                  We've sent a verification code to your WhatsApp.
                  <br />
                  Please enter it below:
                </p>
                <div>
                  <label className="block text-gray-300 mb-2">Verification Code</label>
                  <div className="relative rounded-xl border border-black p-0.5 bg-gradient-to-r from-[#4895AA] to-[#4895AA]">
                    <div className="absolute -bottom-1 -right-1 w-[calc(100%-2px)] h-[calc(100%-2px)] bg-[#FCBF11] rounded-xl -z-10"></div>
                    <div className="absolute -bottom-1.5 -right-1.5 w-[calc(100%+2px)] h-[calc(100%+2px)] bg-[#FCBF11] rounded-xl -z-20"></div>
                    <div className="flex bg-white rounded-[10px] border border-black overflow-hidden h-[50px]">
                      <input
                        type="text"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        placeholder="123456"
                        className="w-full h-[50px] px-4 text-left border-0 rounded-none focus:ring-0 bg-transparent"
                        style={{
                          fontFamily: "'Good Headline Pro Cond', sans-serif",
                          fontSize: '20px'
                        }}
                      />
                    </div>
                  </div>
                  <p className="text-gray-400 text-sm mt-2">
                    The code will expire in 5 minutes
                  </p>
                </div>
                <button
                  onClick={handleVerifyCode}
                  className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors"
                >
                  Verify & Continue
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
} 
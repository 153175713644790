import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Calendar, Clock, ArrowLeft } from 'lucide-react';
import klickieLogo from '../assets/klickie-logo.png';

const API_URL = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8080' 
    : process.env.REACT_APP_API_URL || 'https://api.klickie.me';

interface Booking {
    id: string;
    startTime: string;
    endTime: string;
    status: string;
    proName: string;
    serviceName: string;
    serviceDetails: {
        name: string;
        price: number;
        duration: number;
    };
}

export default function MyBookings(): JSX.Element {
    const { clientId } = useParams();
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await fetch(`${API_URL}/api/my-bookings/${clientId}`);
                if (!response.ok) throw new Error('Failed to fetch bookings');
                const data = await response.json();
                setBookings(data);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to load bookings');
            } finally {
                setLoading(false);
            }
        };

        fetchBookings();
    }, [clientId]);

    const formatBookingTime = (booking: Booking) => {
        const start = new Date(booking.startTime);
        return start.toLocaleString([], {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    if (loading) return <div className="min-h-screen bg-gray-100 flex items-center justify-center">Loading...</div>;
    if (error) return <div className="min-h-screen bg-gray-100 flex items-center justify-center text-red-500">{error}</div>;

    const upcomingBookings = bookings.filter(b => new Date(b.startTime) > new Date());
    const pastBookings = bookings.filter(b => new Date(b.startTime) <= new Date());

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="max-w-4xl mx-auto p-4">
                {/* Header */}
                <div className="flex items-center justify-between mb-8">
                    <button 
                        onClick={() => window.history.back()} 
                        className="flex items-center text-gray-600 hover:text-gray-800"
                    >
                        <ArrowLeft className="w-5 h-5 mr-2" />
                        Back
                    </button>
                    <img src={klickieLogo} alt="Klickie Logo" className="h-8" />
                </div>

                <h1 className="text-2xl font-bold mb-6">My Appointments</h1>

                {/* Upcoming Bookings */}
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">Upcoming</h2>
                    {upcomingBookings.length === 0 ? (
                        <p className="text-gray-500">No upcoming appointments</p>
                    ) : (
                        <div className="space-y-4">
                            {upcomingBookings.map(booking => (
                                <div key={booking.id} className="bg-white rounded-lg shadow p-4">
                                    <div className="flex items-start justify-between">
                                        <div>
                                            <h3 className="font-semibold text-lg">{booking.serviceDetails.name}</h3>
                                            <p className="text-gray-600">with {booking.proName}</p>
                                            <div className="flex items-center mt-2 text-gray-500">
                                                <Calendar className="w-4 h-4 mr-2" />
                                                <span>{formatBookingTime(booking)}</span>
                                            </div>
                                            <div className="mt-2 text-gray-500">
                                                <Clock className="w-4 h-4 inline mr-2" />
                                                <span>{booking.serviceDetails.duration} minutes</span>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <span className="text-lg font-semibold">€{booking.serviceDetails.price}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* Past Bookings */}
                <div>
                    <h2 className="text-xl font-semibold mb-4">Past</h2>
                    {pastBookings.length === 0 ? (
                        <p className="text-gray-500">No past appointments</p>
                    ) : (
                        <div className="space-y-4">
                            {pastBookings.map(booking => (
                                <div key={booking.id} className="bg-white rounded-lg shadow p-4 opacity-75">
                                    <div className="flex items-start justify-between">
                                        <div>
                                            <h3 className="font-semibold">{booking.serviceDetails.name}</h3>
                                            <p className="text-gray-600">with {booking.proName}</p>
                                            <div className="flex items-center mt-2 text-gray-500">
                                                <Calendar className="w-4 h-4 mr-2" />
                                                <span>{formatBookingTime(booking)}</span>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <span className="font-semibold">€{booking.serviceDetails.price}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
} 
import axios from 'axios';

const CalendarIntegration = () => {
    const startIntegration = async () => {
        try {
            const response = await axios.get('http://localhost:8080/auth-link');
            window.location.href = response.data.url; // Redirect to Cronofy auth page
        } catch (error) {
            console.error('Error starting calendar integration:', error);
        }
    };

    return (
        <div>
            <button onClick={startIntegration}>Integrate Calendar</button>
        </div>
    );
};

export default CalendarIntegration;
